import "@materializecss/materialize";

document.addEventListener("DOMContentLoaded", () => {
  // Init sidenav
  const sidenav = M.Sidenav.init(document.querySelectorAll(".sidenav"), {});
  M.Collapsible.init(document.querySelectorAll(".collapsible"), {});

  // Init modals
  const modals = document.querySelectorAll(".modal");
  M.Modal.init(modals, {});

  // Smooth scrolling from navigation links
  document.querySelectorAll("a.smooth").forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      const offsetTop = document.querySelector(href).offsetTop;
      scroll({
        top: offsetTop - 30,
        behavior: "smooth",
      });
      sidenav.forEach((sn) => sn.close());
    });
  });

  // Set current year in footer
  document.querySelector("#copyright-year").textContent = new Date().getFullYear();
});
